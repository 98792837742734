'use client'

import dynamic from 'next/dynamic'

export const DynamicAboutSection = dynamic(
  () => import('./about-section').then((comp) => comp.AboutSection),
  {
    ssr: false,
  }
)
