'use client'

import dynamic from 'next/dynamic'
import { EmptyContainerLoader } from 'ui'

export const DynamicPromotionCardSlider = dynamic(
  () =>
    import('./promotion-card-slider').then((comp) => comp.PromotionCardSlider),
  {
    ssr: false,
    loading: () => <EmptyContainerLoader />,
  }
)
