'use client'

import { useEffect, type FC } from 'react'
import { Autoplay, Carousel, CarouselContent, CarouselItem } from 'ui'
import { usePromotionsStore, type Promotion } from '@/services/promotions'
import { DynamicPromotionCardSlider } from './dynamic-promotion-card-slider'

type HeroSliderProps = {
  promos: Promotion[]
}

export const PromotionsSlider: FC<HeroSliderProps> = ({ promos }) => {
  const { promotions, setPromotions } = usePromotionsStore()

  const proms = promotions.length ? promotions : promos

  useEffect(() => {
    if (promos.length && !promotions.length) {
      setPromotions(promos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promos])

  if (!proms.length) return null

  return (
    <Carousel
      className="w-full"
      key={JSON.stringify(proms)}
      opts={{
        loop: true,
      }}
      plugins={[
        Autoplay({
          delay: 2500,
          stopOnMouseEnter: true,
          stopOnInteraction: false,
        }),
      ]}
    >
      <CarouselContent>
        {proms
          .filter((promo) => promo.available)
          .map((promotion) => (
            <CarouselItem className="!pl-0" key={promotion.id}>
              <DynamicPromotionCardSlider promotion={promotion} />
            </CarouselItem>
          ))}
      </CarouselContent>
    </Carousel>
  )
}
