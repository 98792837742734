import { create } from 'zustand'
import type { Promotion } from './promotions.types'

export type PromotionStoreProps = {
  promotions: Promotion[]
}

export type PromotionsStoreActions = {
  setPromotions: (promotions: Promotion[]) => void
  addPromotion: (promotion: Promotion) => void
  deletePromotion: (promotionId: number) => void
  editPromotion: (promotion: Promotion) => void
}

export const usePromotionsStore = create<
  PromotionStoreProps & PromotionsStoreActions
>((set) => ({
  promotions: [],
  setPromotions: (promotions) => set({ promotions }),
  addPromotion: (promotion) =>
    set((state) => ({ promotions: [...state.promotions, promotion] })),
  deletePromotion: (promotionId) =>
    set((state) => {
      const updatedPromotions = state.promotions.filter(
        (promotion) => promotion.id !== promotionId
      )

      return { promotions: updatedPromotions }
    }),
  editPromotion: (promotion) =>
    set((state) => {
      const updatedPromotions = state.promotions.map((p) => {
        if (p.id === promotion.id) {
          return promotion
        }
        return p
      })

      return { promotions: updatedPromotions }
    }),
}))
