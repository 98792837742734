import { request, responseAdapter } from 'libs'
import { getHeaders } from '@/shared'
import type { PublicPromotionResponse } from './promotions.types'

export const getPublicPromotions =
  async (): Promise<PublicPromotionResponse> => {
    const url = '/promotions/public'

    const response = await request.get(url, {
      cache: 'no-store',
      headers: getHeaders().headers,
    })

    const data = await response.json()

    return responseAdapter({ data, status: response.status })
  }
