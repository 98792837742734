import(/* webpackMode: "eager", webpackExports: ["DynamicAboutSection"] */ "/app/apps/web/src/app/[locale]/(default)/(home)/_components/about-section/dynamic-about-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionsSlider"] */ "/app/apps/web/src/app/[locale]/(default)/(home)/_components/promotions-section/promotions-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicServiceList"] */ "/app/apps/web/src/app/[locale]/(default)/(home)/_components/services-section/dynamic-service-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicShopSection"] */ "/app/apps/web/src/app/[locale]/(default)/(home)/_components/shop-section/dynamic-shop-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedMessage"] */ "/app/apps/web/src/components/formatted-message.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/shared/hooks/use-address.form.ts");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/shared/hooks/use-gender.ts");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/shared/hooks/use-mounted.ts");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/shared/hooks/use-otp-timer.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.11.1_next@14.2.3_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
